// extracted by mini-css-extract-plugin
export var blog_post_footer = "blog-post-footer-module--blog_post_footer--2ac2d";
export var blog_post_footer_all_posts = "blog-post-footer-module--blog_post_footer_all_posts--f386a";
export var blog_post_footer_linked_blog = "blog-post-footer-module--blog_post_footer_linked_blog--39f8e";
export var blog_post_footer_linked_blog_box = "blog-post-footer-module--blog_post_footer_linked_blog_box--4544e";
export var blog_post_footer_linked_blog_box_image = "blog-post-footer-module--blog_post_footer_linked_blog_box_image--1e9b9";
export var blog_post_footer_linked_blog_date = "blog-post-footer-module--blog_post_footer_linked_blog_date--e5be3";
export var blog_post_footer_linked_blog_flex = "blog-post-footer-module--blog_post_footer_linked_blog_flex--4ca2d";
export var blog_post_footer_linked_blog_outside = "blog-post-footer-module--blog_post_footer_linked_blog_outside--cafd2";
export var blog_post_footer_read_more = "blog-post-footer-module--blog_post_footer_read_more--6d855";
export var blog_post_footer_share = "blog-post-footer-module--blog_post_footer_share--3862f";